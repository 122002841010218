<template>
  <div id="indexPage" class="">
    <div class="nav-list" v-if ="navlist && navlist.length">
      <div class="oneblock" v-for = "(item,index) in navlist" :key = "index" @click="go(item)">
        <dl>
          <dt><img :src="item.iconurl" alt=""></dt>
          <dd>{{item.text}}</dd>
        </dl>
      </div>
    </div>
  </div>
</template> 
<script>
import wx from 'weixin-js-sdk'
export default {
  name: "indexPage",
  data() {
    return {
      navlist:[
        {
          text:'设置生产管理',
          iconurl:require("@/assets/img/i15.png"),
          path:'/ProductionSettings',
          navid:3
        },
        {
          text:'生产管理详情',
          iconurl:require("@/assets/img/i15.png"),
          path:'/ProductionDetails',
          navid:3
        },
        {
          text:'质安选择位置',
          iconurl:require("@/assets/img/i16.png"),
          path:'/SelectUnits',
          navid:2
        },
        {
          text:'质安问题位置',
          iconurl:require("@/assets/img/i17.png"),
          path:'/UnitsDetail',
          navid:1
        },
        {
          text:'数字工地',
          iconurl:require("@/assets/img/i17.png"),
          path:'/FakeDigitalSite',
          navid:1
        },
        {
          text:'返回',
          iconurl:require("@/assets/img/i17.png"),
          path:'',
          navid:1
        }
      ]
    };
  },
  components: {},
  computed: {
  },
  mounted() {
    console.log(this.$route.query)
  },

  methods: {
    go(item){
      console.log(item,68)
      if(item.path){
        this.$router.push({
          path: item.path,
          query: item
        });
      }else{
        // wx.miniProgram.navigateTo({url: '/login/index'})
        wx.miniProgram.navigateBack({delta: 1})

        
      }
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#indexPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow-y: auto;
  box-sizing: border-box;
  .nav-list{
    &::after{
      content:'';
      display:block;
      clear:both;
    }
    .oneblock{
      float:left;
      width:50%;
      box-sizing:border-box;
      border-right:1px solid #f7f7f7;
      border-bottom:1px solid #f7f7f7;
      &:nth-child(2n){
        border-right:none;
      }
      height:1.7rem;
      position:relative;
      font-size: 14px;
      color:#666666;
      text-align: center;
      dt{
        margin:0.3rem 0 8px;
        img{
          width:0.7rem;
          height:0.7rem;
        }
      }
    }
  }
}
</style>